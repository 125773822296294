import React, { useState, useEffect, useContext } from 'react'
import { FilterProductContext } from '../../context/filterProduct'
import './styles/style.css'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components'
import Header from '../../components/Header'
import SidebarFilterProduct from '../../components/SidebarFilterProduct'
import HeaderMobile from '../../components/HeaderMobile'
import HeaderSecondary from '../../components/HeaderSecondary'
import { SidebarContext } from '../../context/sidebar'
import SidebarCart from '../../components/SidebarCart'
import api from '../../services/api'
import SidebarFilter from '../../components/SidebarFilterTest';
import { motion } from 'framer-motion'
import Container from '../../components/Container'
import FilterProductsMobile from '../../components/FilterProductsMobile';
import { CartContext } from '../../context/cart'
import CadMenu from '../../components/NewCard'
import ModalFilterProducts from '../../components/ModalFilterProducts';
import HorizontalCard from '../../components/HorizontalCard';

const Products = () => {
    const { handleAddToCart } = useContext(CartContext);
    const { handleSidebar } = useContext(SidebarContext)

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    const freeShippingProduct = queryParams.get('freeShipping');
    const priceProduct = queryParams.get("price")

    const { dataProducts,getAllProductsFilter } = useContext(FilterProductContext)

    const [page, setPage] = useState(1); // Estado para a página
    const [mobile, setMobile] = useState()


    function handleResize() {
        if (window.innerWidth <= 712) {
            setMobile(true)
            handleSidebar(false)

        } else {
            setMobile(false)
            handleSidebar(true)

        }
    }

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 712) {
                setMobile(true)
                handleSidebar(false)
            } else {
                setMobile(false)
                handleSidebar(true)

            }
        }

        // Adiciona o event listener
        window.addEventListener('resize', handleResize);

        // Remove o event listener ao desmontar o componente
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

        handleResize()

    }, [])

    useEffect(() => {
        handleSidebar(true)
        handleResize()

    },[])


    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries.some(entry => entry.isIntersecting)) {
                setPage(prevPage => prevPage + 1);
            }
        });
    
        const sentinela = document.querySelector("#sentinela");
        if (sentinela) observer.observe(sentinela);
    
        return () => {
            if (sentinela) observer.unobserve(sentinela);
        };
    }, []); // <-- Remova `page` das dependências
    


    useEffect(() => {
        getAllProductsFilter(page, category, freeShippingProduct, priceProduct)
    },[page,category,freeShippingProduct,priceProduct])
    return (


        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Header /> 
            <SidebarCart />
            <HeaderMobile />
            <HeaderSecondary />
            <SidebarFilter mobile={mobile} />

            <section className='container_flex_products'>
                <div className='colum_primary_flex_products'>
                    <SidebarFilterProduct />
                </div>

                <div className='colum_secondary_flex_products'>
                    <FilterProductsMobile/>
                    <div className='container_cards_data'>
                        {
                            dataProducts?.map(item => {
                                return (

                                    <HorizontalCard onClick={() => handleAddToCart(item)} weight={item.weight} free_shipping={item.free_shipping} name={item.name} promotion_price={item.promotion_price} product_id={item.product_id} price={item.price} description={item.description} product_image={item.product_image} category_image={item.category_image} />
                                )
                            })
                        }
                              {
                            dataProducts?.map(item => {
                                return (

                                    <CadMenu onClick={() => handleAddToCart(item)} weight={item.weight} free_shipping={item.free_shipping} name={item.name} promotion_price={item.promotion_price} product_id={item.product_id} price={item.price} description={item.description} product_image={item.product_image} category_image={item.category_image} />
                                )
                            })
                        }
                    <div id="sentinela" style={{width:"120px",height:"120px"}}></div>
                    </div>

                </div>


            </section>
            <ModalFilterProducts/>
            <Container/>
        </motion.div>

    )
}

export default Products