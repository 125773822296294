import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import api from '../../services/api'
import { useLocation } from 'react-router-dom';

const CardWrapper = styled.div`
    padding-top: 5px    ;
    margin-top: 0.9rem;
    background-color: white;
    width: 323.75px;
    height: 696px;
    box-shadow: 0 0 5px #dddddd;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width:820px){
        height: 290px;
        overflow-y: auto;
        margin:1.2rem auto;

        nav{
            display: block!important;
        }

    }

    nav{
        margin-left: 0rem !important;

    }

    li{
        &:hover {
            color: black;
            border: 1px solid black;

            svg,img{
                color:white;
            }
        }
    }

    ul li {
        
        list-style: none;
        text-decoration: none;
    }


    .checked_li{
        cursor: pointer;
        color: #565D6F;
        font-size: 0.87rem;
        font-family: "Poppins", serif;
        font-weight: 500;
        font-style: normal;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        padding-left: 0rem;
        width: 263.75px ;
        height: 42px;
        border-radius: 8px;
        border: 1px solid black;
        background-color: #ffffff;
        padding-left: 0.4rem;

        margin-top: 0.62rem;

    }

    li > img {
        object-fit: contain;
        width: 22px;
        height:22px;
        margin-right: 0.6rem;
    }

    .not_check_li{
        cursor: pointer;
        color: #565D6F;
        font-size: 0.87rem;
        font-family: "Poppins", serif;
        font-weight: 500;
        font-style: normal;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        padding-left: 0.4rem;
        width: 263.75px ;
        height: 42px;
        border-radius: 8px;
        background-color: #ffffff;
        margin-top: 0.62rem;
    }

    li > svg{
        margin-right: 0.51rem;
    }

`
const SidebarPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('page');
    const [data, setData] = useState([])
    const [page,setPage] = useState()

    const get = async () => {
        const response = await api.get("buttons/get/all")

        if (response.status == 200) {
            setData(response.data)
        }
    }
    const getByPage = async (id) => {
        const response = await api.get(`pages/by/button/${id}`)
        if(response.status == 200){
            window.location.href=`/institucional?page=${response.data[0].name_page}`
        }
    }


    useEffect(() => {
        get()
    }, [])

    return (

        <CardWrapper>

            <nav>
                <ul>
                    {data?.map(item => {
                        return (
                            <li onClick={() => {getByPage(item.name)}} className={'not_check_li'}>
                                <img   src={item.image} />
                                {item.name}</li>
                        )
                    })}


                </ul>
            </nav>

        </CardWrapper>
    )
}

export default SidebarPage