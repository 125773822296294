import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
const Card = styled.div`

    display: none;

    @media screen and (max-width:820px){

        position: relative;
    display: flex;
    padding: 0.9rem;
    width: 100%;
    align-items: center;
    border-bottom: 3px solid #dddddd;        
    }


`
const ImageCard = styled.img`
    width: 110px;
    height: 110px;

    margin-right: 0.9rem;
`
const CardText = styled.div`
    .textPrimary{
        font-size: 0.9rem;
        font-family: "Poppins", serif;
        font-weight: 500;
    }
    .textPrice{
        font-size: 0.8rem;
        font-family: "Poppins", serif;
        height: 6px;
    }

    .textPromotionalPrice{  
        color:#fe5000;
        font-size: 0.9rem;

        font-family: "Poppins", serif;
        font-weight: 500;
        height: 0.7rem;
    }
    
    .textTypePayment{
        color:#1d992e;
        width: 220px;
        font-family: "Poppins", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 0.7rem;
        text-align: left;
        height: 7px;
    }
    .textInstallmentPayment{
        color:#151515dd;
        width: 220px;
        font-family: "Poppins", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 0.7em;
        text-align: left;
        height: 0px;
        margin-bottom: 1.9rem;
    }
`
const MarkImage = styled.img`
    position: absolute;
    top: 0;
    left: 15px;
    width: 39px;
    height: 39px;

`
const FreeShipping = styled.div`
    width: 60%;

    display: flex;
    justify-content: center;
    height: 30px;
    background-color: #1d992e;
    color: white;
    border-end-end-radius: 2px;
    border-end-start-radius:2px;
    margin-bottom: 0.6em;

    p {    
    font-family: "Poppins", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 0.8rem;
    height: 0px;
    margin-top: 0.3rem;

    }

    svg{
        margin-top: 0.6rem;
        margin-right: 0.4rem;
    }

`
const HorizontalCard = ({ name, price, promotion_price, product_image, category_image, product_id,free_shipping }) => {
    const navigate = useNavigate()
    const [installMent, setInstallMent] = useState()
    const [promotionalPrice, setPromotionalPrice] = useState()
    const [descount, setDescount] = useState()


    useEffect(() => {
        const formmatedPrice = price.replace(",", ".")
        const formmatedPromotional = promotion_price.replace(",", ".")
        setPromotionalPrice(formmatedPromotional)
        setInstallMent(Number(formmatedPromotional) / 2)


    }, [promotion_price])
    return (
        <Card onClick={() => navigate(`/product/?id=${product_id}`)}>
            <MarkImage src={category_image} />
            <ImageCard src={product_image} />
            <CardText>
                <p className='textPrimary'>{name}</p>
                {free_shipping == "Sim"
                
                ?
                <FreeShipping>
                    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.83333 6.33334L1.33333 5.33334H4.83333L4.43333 4.33334H1.16667L0.666666 3.33334H5.86667L5.46667 2.33334H0.573333L0 1.33334H2.5C2.5 0.97971 2.64048 0.64057 2.89053 0.39053C3.14057 0.14048 3.47971 0 3.83333 0H11.8333V2.66667H13.8333L15.8333 5.33334V8.66671H14.5C14.5 9.19711 14.2893 9.70581 13.9142 10.0809C13.5392 10.4559 13.0304 10.6667 12.5 10.6667C11.9696 10.6667 11.4609 10.4559 11.0858 10.0809C10.7107 9.70581 10.5 9.19711 10.5 8.66671H7.83333C7.83333 9.19711 7.62262 9.70581 7.24755 10.0809C6.87248 10.4559 6.36377 10.6667 5.83333 10.6667C5.3029 10.6667 4.79419 10.4559 4.41912 10.0809C4.04405 9.70581 3.83333 9.19711 3.83333 8.66671H2.5V6.33334H1.83333ZM12.5 9.66671C12.7652 9.66671 13.0196 9.56131 13.2071 9.37381C13.3947 9.18621 13.5 8.93191 13.5 8.66671C13.5 8.40141 13.3947 8.14711 13.2071 7.95961C13.0196 7.77201 12.7652 7.66671 12.5 7.66671C12.2348 7.66671 11.9804 7.77201 11.7929 7.95961C11.6054 8.14711 11.5 8.40141 11.5 8.66671C11.5 8.93191 11.6054 9.18621 11.7929 9.37381C11.9804 9.56131 12.2348 9.66671 12.5 9.66671ZM13.5 3.66667H11.8333V5.33334H14.8067L13.5 3.66667ZM5.83333 9.66671C6.09855 9.66671 6.35291 9.56131 6.54044 9.37381C6.72798 9.18621 6.83333 8.93191 6.83333 8.66671C6.83333 8.40141 6.72798 8.14711 6.54044 7.95961C6.35291 7.77201 6.09855 7.66671 5.83333 7.66671C5.56812 7.66671 5.31376 7.77201 5.12623 7.95961C4.93869 8.14711 4.83333 8.40141 4.83333 8.66671C4.83333 8.93191 4.93869 9.18621 5.12623 9.37381C5.31376 9.56131 5.56812 9.66671 5.83333 9.66671Z" fill="white" />
                    </svg>
                    <p>Frete grátis</p>

                </FreeShipping> 
                :
                <></>
                }
           
                <p className='textPrice'><s>De R$ {Number(price).toLocaleString("pt-br", { minimumFractionDigits: 2 })}</s></p>
                <p className='textPromotionalPrice'>Por R$ {Number(promotion_price).toLocaleString("pt-br", { minimumFractionDigits: 2 })}</p>
                <p className="textTypePayment" >à vista no Pix ou Boleto</p>
                <p className="textInstallmentPayment" onClick={() => navigate(`/product/?id=${product_id}`)}>ou R$ {promotion_price.replace(".", ",")} em 12x de R$ {Number(installMent).toLocaleString("pt-BR", { minimumFractionDigits: 2 })} sem juros no cartão</p>

            </CardText>
        </Card>
    )
}

export default HorizontalCard