import React, { useState, useContext, useEffect } from 'react'
import api from '../../services/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { SidebarContext } from '../../context/sidebar'
import { useLocation } from 'react-router-dom'

const Collapse = ({ name, image, id, product_id,category_data }) => {
    const { sidebarState, handleSidebar, handleInputSidebar, inputSidebar, category } = useContext(SidebarContext)
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const category_id = queryParams.get('category_id');
    const navigate = useNavigate()
    const [count, setCount] = useState([])
    const [stateEyeClick, setStateEyeClick] = useState(false)
    const [mobile, setMobile] = useState(false)


    useEffect(() => {

        function handleResize() {
            if (window.innerWidth <= 712) {
                setMobile(true)
            } else {
                setMobile(false)
            }

        }
        handleResize()
    }, [])




    const redirectToProductPage = async (category) => {

        if (count == 0) {
            handleInputSidebar(false)
        }

        if (mobile) {
            handleSidebar(false)
        }
        window.location.href=`/products/?category=${name}&category_id=${category}&freeShipping=Não&price=1`

    };

    const getProducts = async () => {
        const response = await api.get(`client/get/product/by/category/${name}`);
        setCount(response.data.count)
    }

    useEffect(() => {
        getProducts()
    }, [name])


    return (

        <>
            <button className='btn_collapse' role="button" aria-expanded="false" aria-controls="collapseExample">

                <div onClick={() => { setStateEyeClick(true); redirectToProductPage(localStorage.getItem("@LAST_CATEGORY")) }} className='flex_btn_collapse' >

                    <div className='container_flex_collapse'>
                        <img src={image} className='icon_flex_collapse' />

                        <div className='circle_collapse'>
                            <p>{count}</p>
                        </div>



                        {/* <p>1/50</p> */}
                    </div>
                    <p>{name}</p>
                    <div style={{ width: "10px" }}></div>

                    <svg width="34" height="34" viewBox="0 1 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="1" width="23" height="23" rx="4.5" fill="white" stroke="#D7D7D7" />
                        <g clip-path="url(#clip0_0_15449)">
                            <path d="M19.5442 11.3867C18.5748 9.80793 16.3698 7.15918 12.4998 7.15918C8.62985 7.15918 6.42485 9.80793 5.45547 11.3867C5.1558 11.8714 4.99707 12.43 4.99707 12.9998C4.99707 13.5697 5.1558 14.1282 5.45547 14.6129C6.42485 16.1917 8.62985 18.8404 12.4998 18.8404C16.3698 18.8404 18.5748 16.1917 19.5442 14.6129C19.8439 14.1282 20.0026 13.5697 20.0026 12.9998C20.0026 12.43 19.8439 11.8714 19.5442 11.3867V11.3867ZM18.4786 13.9586C17.6461 15.3123 15.7617 17.5904 12.4998 17.5904C9.23797 17.5904 7.3536 15.3123 6.5211 13.9586C6.34306 13.6705 6.24876 13.3385 6.24876 12.9998C6.24876 12.6611 6.34306 12.3292 6.5211 12.0411C7.3536 10.6873 9.23797 8.40918 12.4998 8.40918C15.7617 8.40918 17.6461 10.6848 18.4786 12.0411C18.6566 12.3292 18.7509 12.6611 18.7509 12.9998C18.7509 13.3385 18.6566 13.6705 18.4786 13.9586V13.9586Z" fill="black" />
                            <path d="M12.5 9.875C11.8819 9.875 11.2777 10.0583 10.7638 10.4017C10.2499 10.745 9.8494 11.2331 9.61288 11.8041C9.37635 12.3751 9.31447 13.0035 9.43505 13.6096C9.55563 14.2158 9.85325 14.7727 10.2903 15.2097C10.7273 15.6467 11.2842 15.9444 11.8903 16.0649C12.4965 16.1855 13.1249 16.1236 13.6959 15.8871C14.2669 15.6506 14.755 15.25 15.0983 14.7361C15.4417 14.2222 15.625 13.6181 15.625 13C15.624 12.1715 15.2944 11.3772 14.7086 10.7914C14.1228 10.2056 13.3285 9.87599 12.5 9.875V9.875ZM12.5 14.875C12.1292 14.875 11.7666 14.765 11.4583 14.559C11.15 14.353 10.9096 14.0601 10.7677 13.7175C10.6258 13.3749 10.5887 12.9979 10.661 12.6342C10.7334 12.2705 10.912 11.9364 11.1742 11.6742C11.4364 11.4119 11.7705 11.2334 12.1342 11.161C12.4979 11.0887 12.8749 11.1258 13.2175 11.2677C13.5601 11.4096 13.853 11.65 14.059 11.9583C14.265 12.2666 14.375 12.6292 14.375 13C14.375 13.4973 14.1775 13.9742 13.8258 14.3258C13.4742 14.6774 12.9973 14.875 12.5 14.875Z" fill="black" />
                        </g>
                        <defs>
                            <clipPath id="clip0_0_15449">
                                <rect width="15" height="15" fill="white" transform="translate(5 5.5)" />
                            </clipPath>
                        </defs>
                    </svg>

                    {/* {stateClick == false

                        ?


                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="18px" height="18px" viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                fill="#000000" stroke="none">
                                <path d="M2492 5109 c-45 -13 -108 -80 -121 -126 -7 -26 -11 -392 -11 -1130
                   l0 -1093 -1113 -2 -1113 -3 -41 -27 c-63 -41 -88 -90 -88 -169 0 -54 5 -72 27
                   -106 15 -22 44 -51 65 -64 l38 -24 1112 -3 1113 -2 2 -1113 3 -1112 24 -38
                   c13 -21 42 -50 64 -65 34 -23 52 -27 107 -27 55 0 73 4 107 27 22 15 51 44 64
                   65 l24 38 3 1112 2 1113 1113 2 1112 3 38 24 c21 13 50 42 65 64 23 34 27 52
                   27 107 0 55 -4 73 -27 107 -15 22 -44 51 -65 64 l-38 24 -1112 3 -1113 2 -2
                   1113 -3 1112 -24 38 c-47 76 -151 113 -239 86z"/>
                            </g>
                        </svg>

                        :
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="18px" height="18px" viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                fill="#000000" stroke="none">
                                <path d="M550 2748 c-55 -29 -95 -79 -112 -140 -24 -91 24 -190 112 -235 l44
                       -23 1966 0 1966 0 44 23 c88 45 136 144 112 235 -17 61 -57 111 -112 140 l-44
                       22 -1966 0 -1966 0 -44 -22z"/>
                            </g>
                        </svg>

                    } */}




                </div>
            </button>
            {/* 
            <div className="collapse"  id={id}>

                {data?.map(item => {
                    return (
                        <div className={stateEyeClick == true ? " card-body card_active" : " card-body"}>
                            <div className='flex_btn_collapse_sub'>

                                <div >
                                <img src={item.product_image}/>

                                    <p>{item.name}</p>
                                </div>
                                <svg onClick={() => { setStateEyeClick(true); redirectToProductPage(item.product_id) }} width="34" height="34" viewBox="0 1 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="1" width="23" height="23" rx="4.5" fill="white" stroke="#D7D7D7" />
                                    <g clip-path="url(#clip0_0_15449)">
                                        <path d="M19.5442 11.3867C18.5748 9.80793 16.3698 7.15918 12.4998 7.15918C8.62985 7.15918 6.42485 9.80793 5.45547 11.3867C5.1558 11.8714 4.99707 12.43 4.99707 12.9998C4.99707 13.5697 5.1558 14.1282 5.45547 14.6129C6.42485 16.1917 8.62985 18.8404 12.4998 18.8404C16.3698 18.8404 18.5748 16.1917 19.5442 14.6129C19.8439 14.1282 20.0026 13.5697 20.0026 12.9998C20.0026 12.43 19.8439 11.8714 19.5442 11.3867V11.3867ZM18.4786 13.9586C17.6461 15.3123 15.7617 17.5904 12.4998 17.5904C9.23797 17.5904 7.3536 15.3123 6.5211 13.9586C6.34306 13.6705 6.24876 13.3385 6.24876 12.9998C6.24876 12.6611 6.34306 12.3292 6.5211 12.0411C7.3536 10.6873 9.23797 8.40918 12.4998 8.40918C15.7617 8.40918 17.6461 10.6848 18.4786 12.0411C18.6566 12.3292 18.7509 12.6611 18.7509 12.9998C18.7509 13.3385 18.6566 13.6705 18.4786 13.9586V13.9586Z" fill="black" />
                                        <path d="M12.5 9.875C11.8819 9.875 11.2777 10.0583 10.7638 10.4017C10.2499 10.745 9.8494 11.2331 9.61288 11.8041C9.37635 12.3751 9.31447 13.0035 9.43505 13.6096C9.55563 14.2158 9.85325 14.7727 10.2903 15.2097C10.7273 15.6467 11.2842 15.9444 11.8903 16.0649C12.4965 16.1855 13.1249 16.1236 13.6959 15.8871C14.2669 15.6506 14.755 15.25 15.0983 14.7361C15.4417 14.2222 15.625 13.6181 15.625 13C15.624 12.1715 15.2944 11.3772 14.7086 10.7914C14.1228 10.2056 13.3285 9.87599 12.5 9.875V9.875ZM12.5 14.875C12.1292 14.875 11.7666 14.765 11.4583 14.559C11.15 14.353 10.9096 14.0601 10.7677 13.7175C10.6258 13.3749 10.5887 12.9979 10.661 12.6342C10.7334 12.2705 10.912 11.9364 11.1742 11.6742C11.4364 11.4119 11.7705 11.2334 12.1342 11.161C12.4979 11.0887 12.8749 11.1258 13.2175 11.2677C13.5601 11.4096 13.853 11.65 14.059 11.9583C14.265 12.2666 14.375 12.6292 14.375 13C14.375 13.4973 14.1775 13.9742 13.8258 14.3258C13.4742 14.6774 12.9973 14.875 12.5 14.875Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_15449">
                                            <rect width="15" height="15" fill="white" transform="translate(5 5.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>


                            </div>
                        </div>
                    )
                })}

            </div> */}

        </>

    )
}

export default Collapse