import React, { createContext, useRef, useState } from 'react';
import api from '../services/api';
export const FilterProductContext = createContext({});

function FilterProductProvider({ children }) {


    const [inputSidebar, setInputSidebar] = useState(false)
    const [inputColumState, setInputColumState] = useState(false)
    const [inputListState, setInputListState] = useState(false)
    const [isAllFiltersOpen, setIsAllFiltersOpen] = useState(true);
    const [filterMobileOpen, setFilterMobileOpen] = useState(false);
    const [typeFilter,setTypeFilter] = useState()
    const [dataProducts,setDataProducts] = useState([])
    const [loading,setLoading] = useState()
    const [categoryProduct,setCategoryProduct] = useState()
    const [freeShipping,setFreeShipping] = useState("Não")

    const getAllProduts = async (page, category) => {
        setLoading(true);
        try {
            const response = await api.get(`client/get/all/products/pagination/?page=${page}&category=${category}&free_shipping=${freeShipping}`);
            if (response.status === 200) {
                setDataProducts(prevCategorys => {
                    const newProducts = response.data.products.filter(newProduct =>
                        !prevCategorys.some(existingProduct => existingProduct.id === newProduct.id)
                    );
                    return [...prevCategorys, ...newProducts];
                });
            }
        } catch (error) {
            console.error('Erro ao buscar produtos:', error);
        } finally {
            setLoading(false);
        }
    };
    const getAllProductsFilter = async (page, category, free_shipping, price) => {
        setLoading(true);
        
        try {
            const response = await api.get(`client/get/all/products/pagination/?page=${page}&category=${category}&free_shipping=${free_shipping}&price=${price}`);
    
            if (response.status === 404) {
                return false;
            }
    
            if (response.status === 200) {
                setDataProducts(prevProducts => {
                    const newProducts = response.data.products.filter(newProduct =>
                        !prevProducts.some(existingProduct => existingProduct.id === newProduct.id)
                    );
                    return [...prevProducts, ...newProducts];
                });
            }
        } catch (error) {
            console.error('Erro ao buscar produtos:', error);
        } finally {
            setLoading(false);
        }
    };
    
    


    const handleFilterSidebarButton = (state) => {
        setInputSidebar(!inputSidebar)
    }
    const handleFilterColunButton = (e) => {
        setInputColumState(e)
    }
    const handleListButton = (id) => {
        setInputListState(id)
    }
    const handleToggleAllFilters = () => {
        setIsAllFiltersOpen(!isAllFiltersOpen);
    };
    const handleFilterMobile = (type) => {
        setFilterMobileOpen(!filterMobileOpen)
        setTypeFilter(type)
    }


    return (
        <FilterProductContext.Provider value={{
            handleFilterSidebarButton,
            handleFilterColunButton,
            handleListButton,
            inputListState,
            inputSidebar,
            filterMobileOpen,
            handleFilterMobile,
            setFilterMobileOpen,
            typeFilter,
            dataProducts,
            getAllProduts,
            getAllProductsFilter,
            loading,
            setFreeShipping,
            setCategoryProduct
        }}>
            {children}
        </FilterProductContext.Provider>
    );
}

export default FilterProductProvider;
