import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Header from '../../components/Header'
import HeaderMobile from '../../components/HeaderMobile'
import HeaderSecondary from '../../components/HeaderSecondary'
import Container from '../../components/Container';
import api from '../../services/api'
import SidebarPage from '../../components/SidebarPage'
import { useLocation } from 'react-router-dom';
import { Editor, EditorState, convertFromRaw } from 'draft-js';


const Row = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 1320px;
    padding-left: 2.7rem;

    .primary{
        font-size: 1.2rem;
        font-family: "Poppins", system-ui;
        font-weight: 500;
    }

    .secondary{
        font-size: 1.0rem;
        line-height: 30px;
        color: #656565;
        font-family: "Poppins", system-ui;
        font-weight: 500;
    }

    @media screen and (max-width:820px){
            padding: 0.9rem;
            width: 100%;
            flex-direction: column;
            align-items: center;

            p{
                text-align: center;
            }
        
    }

`
const CardWrapper = styled.section`
    display: flex;
    width: 1320px;
    padding-left: 2.5rem;
    height: 100%;
    margin: 0 auto;

    @media screen and (max-width:820px){
        width: 100%;
        flex-direction: column;
        padding-left: 0rem;

    }

`
const BannerPage = styled.img`
    height: auto;
    object-fit: contain;
    margin-bottom: 1.0rem;
`
const ContainerData = styled.div`
    margin-top: 0.9rem;
    padding: 0.4rem;
    margin-left: 0.9rem;
    box-shadow: 0 0 5px #dddddd;
    display:flex;
    width: 920px;
    flex-direction:column;

    @media screen and (max-width:820px){
        margin-top: 0.9rem;
    padding: 0.4rem;
    margin-left: 0rem;
    box-shadow: 0 0 5px #dddddd;
    display:flex;
    flex-direction: column;
    width:95%;
    margin: 0 auto;
    flex-direction:column;
    }


`

const Institucional = () => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty()); // Estado inicial vazio do Editor
    const [image, setImage] = useState()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('page');

    const get = async () => {
        const response = await api.get(`/pages/get/${id}`)
        console.log(response.data)
        if (response.status == 200) {
            setImage(response.data[0].image)
            const contentState = convertFromRaw(JSON.parse(response.data[0].description));
            setEditorState(EditorState.createWithContent(contentState));

        }
    }
    

    useEffect(() => {
        get()
    }, [])

    return (
        <Container>
            <Header />
            <HeaderMobile />
            <HeaderSecondary />
            <CardWrapper>
                <SidebarPage />
                <ContainerData>
                <BannerPage src={image} />
                <Editor
                    readOnly={true}
                    editorState={editorState}
                    onChange={() => <></>}

                />

                </ContainerData>
            </CardWrapper>
        </Container>
    )
}
export default Institucional