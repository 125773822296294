import React, { useContext, useState,useEffect } from 'react'
import styled from 'styled-components'
import { FilterProductContext } from '../../context/filterProduct'
import { IoClose } from "react-icons/io5";
import { useLocation } from 'react-router-dom'
import api from '../../services/api';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';

const ModalProduct = styled.div`
    
    transition: all .2s;
    height: ${(props) => (props.active ? " 420px" : "")};
    position: fixed;
    bottom:${(props) => (props.active ? "0" : "-500px")};
    z-index: 9999999999;
    background-color: white;
    box-shadow: 0 0 5px #dddddd;
    border-top-right-radius: 19px;
    border-top-left-radius: 19px;
    width: 100%;
    height: 420px;
    

`
const ModalHeader = styled.div`

`
const ModalBody = styled.div`
    position: relative;
    padding-left: 0.6rem;
    height: 100%;
    padding-top: 0.9rem;

    .textPrimary{
        font-family: "Poppins", serif;

    }

`
const BottomMenu = styled.div`
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
`
const Button = styled.button`
    margin:0 auto;
    display:flex;
    padding-top: 0.3rem;
    justify-content: center;
    margin-bottom: 1rem;
    font-family: "Poppins", serif;
    font-weight: 600;
    font-size: 0.9rem;
    border:none;
    color:white;
    width: 230px;
    height: 35px;
    border-radius:5px;
    background-color: #3483FA;

`
const CleanButton = styled.button`
    margin:0 auto;
    width: 150px;
    font-family: "Poppins", serif;
    font-size: 0.9rem;
    border:none;
    color:black;
    background-color: white;
    height: 30px;
    border-radius:5px;
    border: 1px solid #3483FA;
`
const Option = styled.button`
    font-family: "Poppins", serif;
    display:flex;
    padding-top: 0.3rem;
    font-size: 0.8rem;
    justify-content: center;
    border: 1px solid #BFBFBF;
    border-radius:30px;
    width:auto;
    min-width:60px;
    height:30px;
    margin-right: 0.4rem;
    margin-top: 0.4rem;
    border: 1px solid ${({ active }) => (active ? "blue" : "#BFBFBF")};

    :active{
        border:1px solid red;
    }


`
const Colum = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;

`
const Row = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 0.6rem;
`
const BoxMenus = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

`
const InputEl = styled.input`

    margin-bottom: 0.9rem;
`



const ModalFilterProducts = () => {
    const navigate = useNavigate()
    const { filterMobileOpen, handleFilterMobile, typeFilter,getAllProductsFilter,loading,setFilterMobileOpen,setCategoryProduct,} = useContext(FilterProductContext)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category_id = queryParams.get('category_id');
    const category = queryParams.get('category');
    const freeShippingProduct = queryParams.get('freeShipping');
    const priceProduct = queryParams.get("price")
    
    const [subCategorys, setSubCategorys] = useState([])
    const [filter,setFilter] = useState()
    const [freeShipping,setFreeShipping] = useState(freeShippingProduct)
    const [selectId,setSelectId] = useState(category)
    const [price,setPrice] = useState(priceProduct)
    
    const get = async () => {
        const response = await api.get(`category/sub/${category_id}`)
        if (response.status == 200) {
            setSubCategorys(response.data)
        }
    }
    const handleFilter = () => {
        if(!filter){
            window.location.href=`/products/?category=${category}&category_id=${category_id}&freeShipping=${freeShipping}&price=${price}`
            getAllProductsFilter(1,filter,freeShipping,price)
            
            setTimeout(() => {
                setFilterMobileOpen(false)
            },[1000])
            return
        }
        window.location.href=`/products/?category=${filter}&category_id=${category_id}&freeShipping=${freeShipping}&price=${price}`
        getAllProductsFilter(1,filter,freeShipping,price)
        
        setTimeout(() => {
            setFilterMobileOpen(false)
        },[1000])
        
        
    }

    useEffect(() => {
        get()
    }, [category_id])

    useEffect(() => {
        getAllProductsFilter(1, category, freeShippingProduct, priceProduct)
    },[category,freeShippingProduct,priceProduct])



    return (
        
        <ModalProduct active={filterMobileOpen}>

            <ModalBody>
                {typeFilter == "mark"

                    ?
                    <>
                        <Row>
                            <p className='textPrimary'>{category_id != "Acessórios" ? "Marcas" : "Tipo de Acessório"}</p>
                            <IoClose onClick={() => handleFilterMobile()} />

                        </Row>
                        <Colum>
                            {subCategorys?.map(item => {
                                return (
                                    <Option active={selectId == item.name ? true : false}  onClick={() => {setFilter(item.name);setSelectId(item.name)}}>
                                        {item.name}
                                    </Option>
                                )
                            })}

                        </Colum>
                    </>
                    :
                    typeFilter == "free_shipping"


                        ?
                        <>
                            <Row>
                                <p className='textPrimary'>Frete grátis</p>
                                <IoClose onClick={() => handleFilterMobile()}  />
                            </Row>

                            <Colum>
                                <Option active={freeShipping == "Sim" ? true : false} onClick={() => setFreeShipping("Sim")} >
                                    Produtos com frete grátis
                                </Option>
                                <Option active={freeShipping == "Não" ? true : false} onClick={() => setFreeShipping("Não")} >
                                    Produtos sem frete grátis
                                </Option>
                            </Colum>
                        </>
                        :
                        <>
                            <Row>
                                <p className='textPrimary'>Preços</p>
                                <IoClose onClick={() => handleFilterMobile()} />
                            </Row>

                            <Colum>
                                <Option  active={price == 1 ? true : false} onClick={() => setPrice(1)}>
                                    Preço Maior / Menor
                                </Option>
                                <Option active={price == 2 ? true : false} onClick={() => setPrice(2)}>
                                    Preço Menor / Maior
                                </Option>
                            </Colum>
                        </>

                }

                <BottomMenu>
                    <Button  onClick={() => handleFilter()}>{loading ?  <ReactLoading type={"bars"} color='white' width={30} /> : "Aplicar Filtro"}</Button>
                </BottomMenu>
            </ModalBody>

        </ModalProduct>
    )
}

export default ModalFilterProducts