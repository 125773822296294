import React,{useContext} from 'react'
import styled from 'styled-components'
import { FilterProductContext } from '../../context/filterProduct'
import { useLocation } from 'react-router-dom';

const CardWrapper = styled.div`
    display: none;
    @media screen and (max-width:820px){
        width: 100%;
        display: block;
    }

`
const CardHeader = styled.div`

    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.7rem;
    padding-left:0.6rem;
    background-color: #fdfdfd;
    box-shadow: 0 0 5px #dddd; 
    color:black;
    .filter_card{
        width: 50px;
        height: auto;
        box-shadow: 0 0 5px #d1d1d1dd;
    }


`
const CardItem = styled.div`
    display: flex;
    align-items:center;
    margin-right: 0.9rem;
    font-family: "Poppins", serif;
    font-size: 0.9rem;

    svg{
        color:black;
        position: relative;
        top: -7px;
        left: 5px;
    }

    &::after{
        content: "";
        border-left: 1px solid #dddddd;
    }


`
const CardRemove = styled.div`
`


const FilterProductsMobile = () => {
    const {handleFilterMobile,setFilterMobileOpen} = useContext(FilterProductContext)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    const freeShippingProduct = queryParams.get('freeShipping');
    const priceProduct = queryParams.get("price")
    const category_id = queryParams.get('category_id');


    return (
        <CardWrapper>

            <CardHeader>
                <CardItem onClick={() => {handleFilterMobile("mark");setFilterMobileOpen(true)}}>
                    <p>{category_id != "Acessórios" ? "Marcas" : "Tipo de Acessório"}</p>
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.415979 1.63281C0.177553 1.35377 0.177553 0.942745 0.415979 0.663703V0.663703C0.71374 0.315218 1.25241 0.315218 1.55018 0.663702L4.24168 3.81371C4.64087 4.28089 5.36303 4.2809 5.76222 3.81371L8.45373 0.663702C8.75149 0.315218 9.29017 0.315218 9.58793 0.663703V0.663703C9.82635 0.942745 9.82635 1.35377 9.58793 1.63281L5.76222 6.11022C5.36303 6.57741 4.64087 6.57741 4.24168 6.11022L0.415979 1.63281Z" fill="#787486" />
                    </svg>

                </CardItem>



                <CardItem  onClick={() => {handleFilterMobile("free_shipping");setFilterMobileOpen(true)}}>
                    <p>Frete grátis</p>
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.415979 1.63281C0.177553 1.35377 0.177553 0.942745 0.415979 0.663703V0.663703C0.71374 0.315218 1.25241 0.315218 1.55018 0.663702L4.24168 3.81371C4.64087 4.28089 5.36303 4.2809 5.76222 3.81371L8.45373 0.663702C8.75149 0.315218 9.29017 0.315218 9.58793 0.663703V0.663703C9.82635 0.942745 9.82635 1.35377 9.58793 1.63281L5.76222 6.11022C5.36303 6.57741 4.64087 6.57741 4.24168 6.11022L0.415979 1.63281Z" fill="#787486" />
                    </svg>

                </CardItem>

                <CardItem onClick={() => {handleFilterMobile("price");setFilterMobileOpen(true)}}>
                    <p>Preço</p>
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.415979 1.63281C0.177553 1.35377 0.177553 0.942745 0.415979 0.663703V0.663703C0.71374 0.315218 1.25241 0.315218 1.55018 0.663702L4.24168 3.81371C4.64087 4.28089 5.36303 4.2809 5.76222 3.81371L8.45373 0.663702C8.75149 0.315218 9.29017 0.315218 9.58793 0.663703V0.663703C9.82635 0.942745 9.82635 1.35377 9.58793 1.63281L5.76222 6.11022C5.36303 6.57741 4.64087 6.57741 4.24168 6.11022L0.415979 1.63281Z" fill="#787486" />
                    </svg>

                </CardItem>


            </CardHeader>

        </CardWrapper>
    )
}

export default FilterProductsMobile