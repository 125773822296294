import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import Container from '../../components/Container';
import Header from '../../components/Header';
import SidebarCart from '../../components/SidebarCart';
import HeaderSecondary from '../../components/HeaderSecondary';
import ContainerCard from '../../components/ContainerCard';
import VerticalCarrousel from '../../components/VerticalCarrousel';
import Card from '../../components/CardLanding';
import toyotaLogo from '../../images/svg/toyota-logo.svg';
import { CarrouselContext } from '../../context/carrousel';
import { CartContext } from '../../context/cart';
import HeaderMobile from '../../components/HeaderMobile';
import CardCarrousel from '../../components/CardCarrousel';
import SidebarFilter from '../../components/SidebarFilterTest';
import { motion } from 'framer-motion'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // certifique-se de importar os estilos do carousel


const useGetBannersHorizontal = () => {
    return useQuery({
        queryKey: ['bannersHorizontal'],
        queryFn: async () => {
            const response = await api.get(`banner/get/2`);
            if (response.status === 200) {
                return response.data; // Certifique-se de ajustar conforme o retorno real da API
            }
            throw new Error('Erro ao buscar banners');
        },
    });
};


const Landing = () => {
    const navigate = useNavigate()
    const [emphasisProducts, setEmphasisProducts] = useState([])
    const [engatesFixos, setEngatesFixos] = useState([])
    const [engatesRemoviveis, setEngatesRemoviveis] = useState([])
    const [acessoriosCarretinha, setAcessoriosCarretinha] = useState([])

    const { cartLenght, handleAddToCart } = useContext(CartContext);
    const { page,session,sessionEngatesFixos,sessionEngatesRemoviveis,acessorios} = useContext(CarrouselContext)
    const [bannersBottom, setBannersBottom] = useState([])

    const { data: bannersHorizontal = [], isLoading: isLoadingBanners, isError: isErrorBanners, error: errorBanners } = useGetBannersHorizontal();

    const getEmphasisProducts = async (page,session) => {
        const response = await api.get(`client/get/all/product?page=${page}&session=Produtos em destaque`)
        setEmphasisProducts(response.data.products)
    }
    const getEngatesFixos = async () => {
        const response = await api.get(`client/get/all/product?page=${sessionEngatesFixos}&session=Engates Com Rabicho Fixo`)
        setEngatesFixos(response.data.products)
    }
    const getEngatesRemoviveis = async () => {
        const response = await api.get(`client/get/all/product?page=${sessionEngatesRemoviveis}&session=Engates Com Rabicho Removível`)
        setEngatesRemoviveis(response.data.products)
    }
    const getAcessorios = async () => {
        const response = await api.get(`client/get/all/product?page=${acessorios}&session=Acessórios para carretinha`)
        setAcessoriosCarretinha(response.data.products)
    }
    const get = async () => {
        const response = await api.get("banner/get/3")
        if (response.status == 404) {
            setBannersBottom([])
            return false
        }
        setBannersBottom(response.data)
    }

    useEffect(() => {

        get()
    }, [])

    useEffect(() => {

        getEmphasisProducts(page,session)

    }, [page,session])

    useEffect(() => {
        getEngatesFixos(sessionEngatesFixos)
    },[sessionEngatesFixos])

    useEffect(() => {
        getEngatesRemoviveis(sessionEngatesRemoviveis)
    },[sessionEngatesRemoviveis])

    useEffect(() => {
        getAcessorios()
    },[acessorios])

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Container>
                <Header data={cartLenght} />
                <HeaderMobile />
                <HeaderSecondary />

                <SidebarFilter />

                <SidebarCart state={true} />
                <Carousel
                    infiniteLoop
                    autoPlay
                    showThumbs={false}
                >
                    {bannersHorizontal?.map((item) => {
                    const splitUrlBanner = String(item.url).split("/")
                   
                    
                    return(
                        <div
                        className="carrousel_banner_image"
                        onClick={() => {
                            navigate(`product/${splitUrlBanner[4]}`);
                        }}
                    >
                        <img src={item.image} loading="lazy" alt="Banner Primary" />
                    </div>
                    )
                })}
                </Carousel>
                {bannersBottom.length > 0

                    ?
                    <div className='container_horizontal_carrousel'>
                        <VerticalCarrousel data={bannersBottom} />
                    </div>

                    :
                    <></>
                }

                <ContainerCard name={"Produtos em destaque"} /> 
                <CardCarrousel name="Produtos em destaque" >
                    {emphasisProducts?.map(item => {
                        console.log(item)
                        return (
                            <div key={item.product_id}>
                                <Card
                                    onClick={() => handleAddToCart(item)}
                                    product_image={item.product_image}
                                    topImage={toyotaLogo}
                                    free_shipping={item.free_shipping}
                                    weight={item.weight}
                                    promotion_price={item.promotion_price}
                                    name={item.name}
                                    category_image={item.category_image}
                                    product_id={item.product_id}
                                    description={item.description}
                                    price={Number(item.price).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}
                                    image="https://firebasestorage.googleapis.com/v0/b/storage-tkg.appspot.com/o/2240a979b0eddbab2223d5aae5a1d99e.webp?alt=media&token=041b005b-397f-41d3-936a-ee57269e2e95"
                                    type_stock={item.type_stock}
                                    qtd_stock={item.qtd_stock}
                                />
                            </div>
                        )
                    })}

                </CardCarrousel>

                <ContainerCard name={"Engates Com Rabicho Fixo"} /> 
                <CardCarrousel name="Engates Com Rabicho Fixo" >
                    {engatesFixos?.map(item => {
                        console.log(item)
                        return (
                            <div key={item.product_id}>
                                <Card
                                    onClick={() => handleAddToCart(item)}
                                    product_image={item.product_image}
                                    topImage={toyotaLogo}
                                    weight={item.weight}
                                    promotion_price={Number(item.promotion_price).toLocaleString('pt-BR',{minimumFractionDigits:2})}
                                    name={item.name}
                                    category_image={item.category_image}
                                    product_id={item.product_id}
                                    description={item.description}
                                    price={Number(item.price).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}
                                    image="https://firebasestorage.googleapis.com/v0/b/storage-tkg.appspot.com/o/2240a979b0eddbab2223d5aae5a1d99e.webp?alt=media&token=041b005b-397f-41d3-936a-ee57269e2e95"
                                    type_stock={item.type_stock}
                                    qtd_stock={item.qtd_stock}
                                />
                            </div>
                        )
                    })}

                </CardCarrousel>

                <ContainerCard name={"Engates Com Rabicho Removível"} /> 
                <CardCarrousel name="Engates Com Rabicho Removível" >
                    {engatesRemoviveis?.map(item => {
                        return (
                            <div key={item.product_id}>
                                <Card
                                    onClick={() => handleAddToCart(item)}
                                    product_image={item.product_image}
                                    topImage={toyotaLogo}
                                    weight={item.weight}
                                    promotion_price={item.promotion_price}
                                    name={item.name}
                                    category_image={item.category_image}
                                    product_id={item.product_id}
                                    description={item.description}
                                    price={Number(item.price).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}
                                    image="https://firebasestorage.googleapis.com/v0/b/storage-tkg.appspot.com/o/2240a979b0eddbab2223d5aae5a1d99e.webp?alt=media&token=041b005b-397f-41d3-936a-ee57269e2e95"
                                    type_stock={item.type_stock}
                                    qtd_stock={item.qtd_stock}
                                />
                            </div>
                        )
                    })}

                </CardCarrousel>


                <ContainerCard name={"Acessórios para carretinha"} /> 
                <CardCarrousel name="Acessórios para carretinha" >
                    {acessoriosCarretinha?.map(item => {
                        return (
                            <div key={item.product_id}>
                                <Card
                                    onClick={() => handleAddToCart(item)}
                                    product_image={item.product_image}
                                    topImage={toyotaLogo}
                                    weight={item.weight}
                                    promotion_price={item.promotion_price}
                                    name={item.name}
                                    category_image={item.category_image}
                                    product_id={item.product_id}
                                    description={item.description}
                                    price={Number(item.price).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}
                                    image="https://firebasestorage.googleapis.com/v0/b/storage-tkg.appspot.com/o/2240a979b0eddbab2223d5aae5a1d99e.webp?alt=media&token=041b005b-397f-41d3-936a-ee57269e2e95"
                                    type_stock={item.type_stock}
                                    qtd_stock={item.qtd_stock}
                                />
                            </div>
                        )
                    })}

                </CardCarrousel>


            </Container>
        </motion.div>
    );
};

export default Landing;
