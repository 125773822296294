import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import HeaderPayment from '../../components/HeaderPayment'
import CardItem from '../../components/CardItem'
import axios from 'axios'
import FooterPayment from '../../components/FooterPayment'
import houseIcon from '../../images/svg/houseIcon.svg'
import { CartContext } from '../../context/cart'
import { UserContext } from '../../context/user'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Footer from "../../components/Footer";

import Animation from '../../components/Animation'
const CardWrapper = styled.div` 
`
const CardItems = styled.div`
    padding-left: 9rem;
    padding-right: 9rem;


    @media screen and (max-width:820px){
        padding-left: 0.9rem;
        padding-right: 0.9rem;


        button{
            transition: all .2s;
            width: 40%;
        }
        
    }
`
const HeaderCardItems = styled.div`

    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 5px solid #EDEDED;

    p{
        font-size: 19px;
        color:#515151;
        font-family: "Lato", serif;
        font-weight: 700;
        font-style: normal;
    }


    @media screen and (max-width:820px){
        width: 100%;
    }

`
const ButtonCard = styled.button`
    font-family: "Lato", serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    width: 245px;
    height: 35px;
    margin-bottom: 0.9rem;
    border-radius: 2px;
    box-shadow: 0 5px 0px #039c03;
    background:#00BF00;
    border: none;

`

const BoxShippingResum = styled.div`

    display: none;

    @media screen and (max-width:820px){
    display: flex;
    align-items: center;
    width: 820px;

    .textPrimary{
        margin-top: 1rem;
        font-size: 19px;
        color:#515151;
        font-family: "Lato", serif;
        font-weight: 700;
        font-style: normal;
        margin-bottom: 1.3rem;
    }

    padding-left: 9rem;
    padding-right: 9rem;

    @media screen and (max-width:820px){
        
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
        padding-left: 0.9rem;
        padding-right: 0.9rem;
    }

    a{
        position: relative;
        top: 35px;
        width: 90px;
        color:#414141;
        font-family: "Lato", serif;
        font-weight: 500;
        font-size: 14px;
        color:#515151;
    }
    }


`

const CardShipping = styled.div`
    width: 100%;
`
const BoxShipping = styled.div`

    display: flex;
    align-items: center;
    width: 820px;

    .textPrimary{
        margin-top: 1rem;
        font-size: 19px;
        color:#515151;
        font-family: "Lato", serif;
        font-weight: 700;
        font-style: normal;
        margin-bottom: 1.9rem;
    }

    padding-left: 9rem;
    padding-right: 9rem;

    @media screen and (max-width:820px){
        
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
        padding-left: 0.9rem;
        padding-right: 0.9rem;
    }

    a{
        position: relative;
        top: 35px;
        width: 90px;
        color:#414141;
        font-family: "Lato", serif;
        font-weight: 500;
        font-size: 14px;
        color:#515151;
    }

`
const BoxTextsShipping = styled.div`
    width: 90%;
`
const CardInputShipping = styled.div`
    position:relative;
    margin-top: 0.1rem;
    img{
        z-index: 99999;
        position: absolute;
    }

    
    input:focus{
        transition: all .2s;
        border-bottom: 1px solid #6a6a6a ;
    }

    .textLocation{
        font-size: 19px;
        color:#414141;
        font-family: "Lato", serif;
        font-weight: 700;
        height: 9px;
    }
    .textStreet{
        height: 9px;
        font-size: 15px;
        color:#535353;
        font-family: "Lato", serif;
        font-weight: 500;
    }

`
const InputShipping = styled.input`
    margin-top: 0px;
    width: 311px;
    border:none;
    outline: none;
    position: relative;
    top: -17px;
    text-indent: 20px;
    padding-bottom: 0.9rem;
    border-bottom:1px solid #dddddd;

    

    @media screen and (max-width:820px){
        width: 100%;
        
    }




`
const BoInputShipping = styled.div`
    label{
        display: flex;
        align-items: center;
    }
    display: flex;
    align-items: center;
    height: 12px;
    z-index: 99999999;
    p{
        transition: all .5s;
        position: relative;
        font-family: "Lato", serif;
        font-weight: 500;
        font-style: normal;
        color:#515151;
        margin-left:24px;
        z-index: 99999;
    }

    .changeToTop{
        transition: all .5s;

        font-size: 0.8rem;
        position: relative;
        left: -20px;
        top: -21px;
    }

    @media screen and (max-width:820px){
        margin-top: 2.8rem;
    }


`
const BoxTypeShipping = styled.div`
    
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-left: 9rem;
    padding-right: 9rem;



    @media screen and (max-width:820px){
        padding-left: 0.9rem;
        padding-right: 0.9rem;


    }

    p{
        color:#515151;
        font-family: "Lato", serif;
        font-weight: 500;
    }

  

`
const CardTypeShipping = styled.div`

    padding: 0.1rem;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #85D024;
    width:520px;
    height: 83px;
    padding-left: 0.4rem; 
    @media screen and (max-width:720px){
    padding: 0.1rem;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 53px;
    padding-left: 0.4rem;        
    }





`
const CardTextsTypeShipping = styled.div`
    
    display: flex;
    margin-left: 0.5rem;
    flex-direction: column;

    
    
    .textPrimary{
        font-weight: 600;
        text-transform: uppercase;
        font-size: 17px;
        color: #1A8A00;
        height:0px;
    }

    .textPrimary::after{
        content: "";
        display: block;
        width: 90%;
        border-bottom: 1px solid #1A8A00;
    }

    .textSecondary{
        font-size: 14px;
        color:#383838;
        font-family: "Lato", serif;
        font-weight: 600;
        position: relative;
        top: 12px;
    }


`
const CardValueTypeShipping = styled.div`


    display:flex;
    justify-content: space-between;
    align-items:center ;
    position: absolute;
    right: 0;
    background-color: #F0FCEE;
    width:90%;
    height: 81px;
    margin-right: 0.1rem;
    padding-right: 0.7rem;

    .textPrice{
        font-family: "Lato", serif;
        font-weight: 800;
        font-size:14px;
        color:#1A8A00;

    }

    @media screen and (max-width:820px){
        display:flex;
    justify-content: space-between;
    align-items:center ;
    position: absolute;
    right: 0;
    background-color: #F0FCEE;
    width: 90%;
    height: 53px;
    padding-right: 0.7rem;

    .textPrice{
        font-family: "Lato", serif;
        font-weight: 800;
        font-size:14px;
        color:#1A8A00;

    }
        
    }



`
const CardResumRequest = styled.div`

  display: none;
  justify-content: space-between;
  @media screen and (max-width:820px){
        display: flex;
        padding-left: 0.9rem;
        padding-right: 0.9rem;
        padding-top: 0rem;
        padding-bottom: 0.5rem;
        height: 35px;
        border-top: 1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;

        p{
            transition: all .5s;
            position: relative;
            font-family: "Lato", serif;
            font-weight: 500;
            color:#515151;
        }

        .textPrice{
            transition: all .5s;
            position: relative;
            font-family: "Lato", serif;
            font-weight: 700;
            color:#2e2e2e;
        }

        .textPriceSecondary{
            transition: all .5s;
            position: relative;
            font-family: "Lato", serif;
            font-weight: 700;
            color:#2e2e2e;
            font-size: 18px;
        }

    }
`
const CardResumRequestDesktop = styled.div`
  padding:1rem;
  height: 60px;
  display: flex;
  justify-content: space-between;

      p{
        font-size: 1.1rem;
        transition: all .5s;
        position: relative;
        font-family: "Lato", serif;
        font-weight: 500;
        color:#515151;

        }
        
        .textPrice{
        font-size: 1.1rem;
        transition: all .5s;
        position: relative;
        font-family: "Lato", serif;
        font-weight: 700;
        color:#515151;

        }


  @media screen and (max-width:820px){
        display: flex;
        padding-left: 0.9rem;
        padding-right: 0.9rem;
        padding-top: 0rem;
        padding-bottom: 0.5rem;
        height: 35px;
        border-top: 1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;

        p{
            transition: all .5s;
            position: relative;
            font-family: "Lato", serif;
            font-weight: 500;
            color:#515151;
        }

        .textPrice{
            transition: all .5s;
            position: relative;
            font-family: "Lato", serif;
            font-weight: 700;
            color:#2e2e2e;
        }

        .textPriceSecondary{
            transition: all .5s;
            position: relative;
            font-family: "Lato", serif;
            font-weight: 700;
            color:#2e2e2e;
            font-size: 18px;
        }

    }
`
const Line = styled.div`


    margin-top: 1em;
    border-bottom: 5px solid #EDEDED;

`
const CardButtons = styled.div`

    margin-top: 2rem;
    padding-left: 9rem;
    padding-right: 9rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    button{
        width: 262.5px;
    }

@media screen and (max-width:820px){
        display:block;
        padding-left: 0.9rem;
        padding-right: 0.9rem;

        button{
            margin-top: 0.9rem;
            width: 100%;
        }

    }
`
const ButtonContinue = styled.button`
    border-radius: 2px;
    font-family: "Lato", serif;
    font-weight: 500;
    color:#FFFFFF;
    width: 100%;
    height: 49px;

    background-color: #000000;
    border: none;

`
const ButtonAdd = styled.button`
    border-radius: 2px;
    width: 100%;
    height: 49px;
    color:#414040;
    background-color: #FFFFFF;
    border: 1px solid #827c7c;

`
const BoxResumRequestDesktop = styled.div`
    
    .textPrimary{
        font-size:1.3rem;
        color:#515151;
        font-family: "Lato", serif;
        font-weight: 700;
    }



    width: 1020px;
    margin-top: -6rem;

    @media screen and (max-width:820px){
        display: none;
        
    }


`
const LineText = styled.div`
        border-bottom: 1px solid #EDEDED;

`

const Clean = styled.div`
    height: 10.2rem;
`

const AnimationData = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    align-items: center;
`

const AnimationImage = styled.div`

    width: 450px;

    @media screen and (max-width:820px){

        width: 70%;
        
    }


`


const Payment = () => {

    const navigate = useNavigate()

    const { cart, price, handleFreight, priceAddress, dateFreight,optionDelivery} = useContext(CartContext)
    const { setZipCode, setStreet, setLocation, zipCode, street, location, setState, state } = useContext(UserContext)
    const [inputFocus, setInputFocus] = useState()
    const [zipCodeErr, setZipCodeErr] = useState()
    const [totalPrice, setTotalPrice] = useState()
    const [animation, setAnimation] = useState(false)
    const [cepIsValid, setCepIsValid] = useState(false)


    const getZipCode = async () => {
        try {
            setAnimation(true)

            if (String(zipCode).length < 8) {
                setAnimation(false)
                setCepIsValid(false)
                return toast.error("Cep inválido", {
                    autoClose: 1500
                })
            }

            const response = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`)
            if (response.status == 200) {
                
                localStorage.setItem("@CEP_SHIPPING",zipCode)
                setStreet(response.data.logradouro)
                setLocation(response.data.localidade)
                setState(response.data.uf)
                setCepIsValid(true)

            }

            setTimeout(() => {
                setAnimation(false)
            }, [1600])


        } catch (error) {
            setAnimation(false)
            setCepIsValid(false)
            return toast.error("Cep inválido", {
                autoClose: 1500
            })

        }


    }

    
    const getZipCodeLocal = async (zipCode) => {
        try {

            if (String(zipCode).length < 8) {
                setAnimation(false)
                setCepIsValid(false)
                return toast.error("Cep inválido", {
                    autoClose: 1500
                })
            }

            const response = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`)
            if (response.status == 200) {
                setZipCode(zipCode)
                handleFreight(zipCode)
                localStorage.setItem("@CEP_SHIPPING",zipCode)
                setStreet(response.data.logradouro)
                setLocation(response.data.localidade)
                setState(response.data.uf)
                setCepIsValid(true)

            }

            setTimeout(() => {
                setAnimation(false)
            }, [1600])


        } catch (error) {
            setAnimation(false)
            setCepIsValid(false)
            return toast.error("Cep inválido", {
                autoClose: 1500
            })

        }


    }

    const storage = () => {

        
        if (!cepIsValid && !localStorage.getItem("@CEP_SHIPPING")) {
            return toast.error("Cep inválido", {
                autoClose: 1500
            })
        }
        if (!zipCode && !localStorage.getItem("@ZIP_CODE")) {
            setZipCodeErr(true)
            return toast.error("Informe um cep", {
                autoClose: 1500
            })
        } else {
            setZipCodeErr(false)
        }
        navigate("/payment/confirm")

    }
    




    useEffect(() => {
        if(localStorage.getItem("@ZIP_CODE")){
            getZipCodeLocal(localStorage.getItem("@ZIP_CODE"))
        }
    },[])


    useEffect(() => {

        const formmatedPrice = String(price).replace(",", ".")
        const formmatedPriceAddress = String(priceAddress).replace(",", ".")
        const total = Number(formmatedPrice) + Number(formmatedPriceAddress)
        setTotalPrice(total)

    }, [priceAddress])



    return (

        <CardWrapper>
            {animation == true

                ?
                <AnimationData>
                    <AnimationImage>
                        <Animation />
                    </AnimationImage>
                </AnimationData>
                :
                <>
                    <HeaderPayment />
                    <CardItems>
                        <HeaderCardItems>
                            <p>Meu Carrinho</p>
                        </HeaderCardItems>
                        {cart?.map((item, index) => {
                            return (
                                <CardItem image={item.product_image} description={item.name} sku={item.sku} price={item.price} category={item.category} index={index} quantity={item.quantity} product_id={item.product_id} />
                            )
                        })}

                    </CardItems>

                    <BoxShipping>
                        <BoxTextsShipping>
                            <p className='textPrimary'>Frete</p>
                            <CardInputShipping>
                                {cepIsValid

                                    ?
                                    <>
                                        <p className='textLocation'>{location}</p>
                                        <p className='textStreet'>{street}</p>
                                        <p className='textStreet'>{zipCode}</p>

                                    </>
                                    :

                                    <>
                                        <BoInputShipping>
                                            <label htmlFor='input_shipping'>
                                                <img src={houseIcon} />
                                                <p className={inputFocus == true ? 'changeToTop' : String(zipCode).length > 0 && zipCode != undefined ? 'changeToTop' : ''}>{inputFocus == true || String(zipCode).length > 0 ? "CEP (0000-000)" : "CEP"}</p>
                                            </label>

                                        </BoInputShipping>
                                        <InputShipping id='input_shipping' onChange={(e) => setZipCode(e.target.value)} name='input_shipping' onFocus={(e) => { setInputFocus(true); }} onBlur={(e) => { setInputFocus(false); getZipCode(e.target.value); handleFreight(e.target.value) }} />
                                    </>
                                }

                            </CardInputShipping>
                        </BoxTextsShipping>

                        {!cepIsValid

                            ?
                            <></>
                            :
                            <a onClick={() => { localStorage.removeItem("@ZIP_CODE");window.location.href="/payment" }}>Alterar CEP</a>

                        }

                    </BoxShipping>


                    {cepIsValid

                        ?
                        <BoxTypeShipping>
                            <CardShipping>
                                <p>Forma de envio:</p>
                                <CardTypeShipping>
                                    {optionDelivery == 1 
                                    
                                    ?
                                    <CardValueTypeShipping>
                                    <CardTextsTypeShipping>
                                        <p className='textPrimary'>Super Rápido </p>
                                        <p className='textSecondary'>1 dia útil</p>
                                    </CardTextsTypeShipping>
                                    <p className='textPrice'>R$ {Number(priceAddress).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                        
                                </CardValueTypeShipping>
                                    :
                                    <CardValueTypeShipping>
                                    <CardTextsTypeShipping>
                                        <p className='textPrimary'>Normal </p>
                                        <p className='textSecondary'>{dateFreight} dias úteis</p>
                                    </CardTextsTypeShipping>
                                    <p className='textPrice'>R$ {Number(priceAddress).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>

                                </CardValueTypeShipping>
                                    }
                              

                                </CardTypeShipping>
                            </CardShipping>
                            <Line />

                            <BoxResumRequestDesktop>
                                <p className='textPrimary'>Resumo do pedido</p>
                                <LineText />
                                <CardResumRequestDesktop>
                                    <p>SubTotal</p>
                                    <p>R$ {Number(price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                                </CardResumRequestDesktop>
                                <LineText />

                                <CardResumRequestDesktop>
                                    <p>Frete</p>
                                    <p>R$ {Number(priceAddress).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                                </CardResumRequestDesktop>
                                <LineText />
                                <CardResumRequestDesktop>
                                    <p className='textPrice'>Total do pedido</p>
                                    <p className='textPrimary'>R$ {Number(totalPrice).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                                </CardResumRequestDesktop>
                                <LineText />
                            </BoxResumRequestDesktop>

                        </BoxTypeShipping>
                        :
                        <></>

                    }

                    {!cepIsValid

                        ?
                        <></>
                        :
                        <BoxShippingResum>
                            <BoxTextsShipping>
                                <p className='textPrimary'>Resumo do pedido</p>
                            </BoxTextsShipping>

                        </BoxShippingResum>
                    }



                    {!cepIsValid

                        ?
                        <></>
                        :
                        <>
                            <CardResumRequest>
                                <p>SubTotal</p>
                                <p>R$ {Number(price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                            </CardResumRequest>

                            <CardResumRequest>
                                <p>Frete</p>
                                <p>R$ {Number(priceAddress).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                            </CardResumRequest>

                            <CardResumRequest>
                                <p className='textPrice'>Total do pedido</p>
                                <p className='textPriceSecondary'>R$ {Number(totalPrice).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                            </CardResumRequest>
                        </>
                    }

                    {!cepIsValid

                        ?
                        <></>
                        :
                        <CardButtons>
                            <ButtonContinue onClick={() => storage()}>Continuar</ButtonContinue>
                            <ButtonAdd onClick={() => window.location.href = "/"}>Escolher mais produtos</ButtonAdd>
                        </CardButtons>
                    }

                    <Clean />

                    <div className='container_page'>
                        <Footer />

                    </div>

                </>
            }
        </CardWrapper>

    )
}

export default Payment